import React from "react";

interface IState {
    hasError?: boolean;
}

class ErrorBoundary extends React.Component<IState> {
    public state = {
        hasError: false,
    };

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidCatch(error: any, errorInfo: any) {
        console.log(error);
        console.log(errorInfo);
    }
    render() {
        if (this.state.hasError === true) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
